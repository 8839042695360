.partner-logos-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 12px;
}

.partner-logo {
  font-size: 2rem;
  margin-bottom: 1.3rem;
  transition: transform 0.3s ease-in-out;
}

.partner-logo:hover {
  transform: scale(1.2);
  color: #ffffff;
}
