* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

.nav-top {
  background: #ffffff;
  border-top: 4px solid #c62026;
  border-radius: 4px;
  height: 120px;
  width: 100%;
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}
.mt-60 {
  margin-top: 60px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-100 {
  margin-top: 100px;
}

.brand-logo {
  margin-left: 30px;
  margin-bottom: 7px;
}

/* nav ul {
  float: right;
  margin-right: 20px;
}

nav ul li {
  display: inline-block;
  line-height: 80px;
  margin: 0 5px;
}

nav ul li .link {
  color: #04364a;
  font-size: 17px;
  font-weight: 500;
  padding: 7px 13px;
  border-radius: 3px;
  text-transform: uppercase;
  text-decoration: none;
} */

.link:hover {
  background: #04364a;
  color: #ffffff;
  transition: 0.5s;
}

.checkbtn {
  font-size: 30px;
  color: #c62026;
  float: right;
  line-height: 80px;
  margin-right: 40px;
  cursor: pointer;
  display: none;
}
#check {
  display: none;
}
@media (max-width: 952px) {
  label.logo {
    font-size: 30px;
    padding-left: 50px;
  }
  nav ul li .link {
    font-size: 16px;
  }
}
@media (max-width: 858px) {
  .checkbtn {
    display: block;
  }
  /* nav ul {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #061a37;
    top: 80px;
    left: -100%;
    text-align: center;
    transition: all 0.5s;
    z-index: 999;
  }
  nav ul li {
    display: block;
    margin: 50px 0;
    line-height: 30px;
  }
  nav ul li .link {
    font-size: 20px;
  } */
  .link:hover {
    background: none;
    color: #ffffff;
  }
  #check:checked ~ ul {
    left: 0;
  }
}

.social-icons {
  color: white;
}
.social-icons:hover {
  color: #c62026;
}

.info-email {
  color: white;
  text-decoration: none;
  font-size: large;
  font-weight: 500;
}

.social-list-group {
  color: #ffffff !important;
  background-color: #061a37 !important;
}

.footer-logo {
  max-width: 150px;
}

footer {
  background-color: #64ccc5;
}

/* EventCard.css */

.event-card {
  border: 1px solid #ddd;
  padding: 16px;
  margin: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex; /* Display event info and image side by side */
  align-items: center; /* Vertically center content */
}

.event-date {
  font-size: 18px;
  font-weight: bold;
  margin-right: 20px; /* Add space between date and event info */
  color: #333;
}

.event-info {
  flex: 1; /* Take remaining space */
}

.event-name {
  font-size: 24px;
  margin: 0;
  color: #333;
}

.event-organizer {
  font-size: 16px;
  color: #777;
}

.event-price {
  font-size: 18px;
  color: #333;
  margin-top: 10px;
}

.event-image {
  max-width: 100px; /* Adjust the image width as needed */
  border-radius: 4px; /* Add a slight border radius to the image */
  margin-left: 20px; /* Add space between the image and event info */
}

/* Sidebar Container */
.sidebar {
  height: 100%;
  background-color: #061a37;
  color: white;
  padding-top: 20px;
}

/* Logo Style */
.logo {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

/* Navigation Links Style */
.nav-links {
  list-style: none;
  padding: 0;
}

.nav-links li {
  padding: 10px 20px;
  cursor: pointer;
}

.nav-links li:hover {
  background-color: #c62026;
}

/*MEMBERSHIP STYLE*/

.membership-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.membership-form h2 {
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
}

.button-form {
  width: 100%;
  padding: 10px;
  background-color: #c62026;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.button-form:hover {
  background-color: #8b171a;
}

/**/

.contact-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-form h2 {
  text-align: center;
}

/* input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus-within {
  border: 3px solid #e2dbdb;
  background-color: #f79494;
  color: #ffffff;
  font-weight: 500;
}

textarea {
  resize: vertical;
  min-height: 100px; /* Adjust as needed */
/*}*/

.strong {
  color: #061a37;
  font-size: 18px;
}
.strong:hover {
  color: #c62026;
}

/*Application Form CSS*/

.course-application-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.course-application-form h2 {
  text-align: center;
}

/* MUI CALEDAR*/

.css-15jcv2p-MuiPickersToolbar-content {
  display: flex;
  justify-content: center !important;
}

.css-3jvy96-MuiTypography-root-MuiDatePickerToolbar-title {
  text-transform: uppercase;
  margin-bottom: 2rem;
  color: orange;
}

.css-1oo3c4e-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  color: #fff;
  background-color: orange !important;
  font-weight: 500;
}

.css-hq2kpw-MuiGrid-root-MuiPickersToolbar-content {
  justify-content: center !important;
  background-color: orange;
  padding: 1rem;
}

.css-1hbyad5-MuiTypography-root {
  display: none !important;
}

.css-4pax7l-MuiPickersLayout-root .MuiPickersLayout-actionBar {
  display: none !important;
}

/* Blog - Tags */
.blog-tags {
  list-style: none;
  margin: 10px 0 20px;
  overflow: hidden;
  padding: 0;
}

.blog-tags li {
  float: left;
}

.all-cat {
  background: #12486b;

  border-radius: 5px 0 5px 0;
}

.blog-tag {
  background: #12486b;
  border-radius: 5px 0 5px 0;
  color: #fff;
  display: inline-block;
  font-size: 10px;
  height: 22px;
  line-height: 22px;
  padding: 0 10px 0 10px;
  position: relative;
  margin: 0 7px 7px 0;
  text-decoration: none;
  --webkit-transition: color 0.2s;
  box-shadow: 1px -1px 0px rgba(0, 0, 0, 0.15) inset;
}
/* 
// .blog-tag::before {
//   background: #fff;
//   border-radius: 10px;
//   box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
//   content: '';
//   height: 6px;
//   right: 10px;
//   position: absolute;
//   width: 6px;
//   top: 8px;
// }

// .blog-tag::after {
//   background: #fdfcfd;
//   border-bottom: 12px solid transparent;
//   border-left: 10px solid #988518;
//   border-top: 11px solid transparent;
//   content: '';
//   position: absolute;
//   right: 0;
//   top: 0;
// } */

.blog-tag:hover {
  background-color: #7d6a8b;
  color: white;
}

.blog-tag:hover::after {
  border-left-color: #7d6a8b;
}
